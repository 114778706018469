<template>
    <div class="auth-main background-image">
        <div class="auth-box">
            <router-view class="auth-body d-flex-c-jfs-ast-gap30"/>
        </div>
    </div>
</template>

<script>
    const a = {
        Login : 'ورود',
        Register : 'ثبت نام',
        OTP : 'شناسایی دو عاملی',
        ForgetPass : 'فراموشی رمز عبور',
        ResetPass : 'تغییر رمز عبور',
    }
    export default {
        name: "AuthBackBox",
        computed:{
            label(){
                return a[this.$route.name]
            }
        }
    }
</script>

<style lang="scss" scoped>
    .auth-main {
        flex-grow: 1;
        min-height: 100%;
        background-image: url("../../assets/background.png");
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: calc(clamp(5px , 1.5% ,15px));

        .auth-box {
            background-color: var(--semiDark);
            border-radius: 9px;
            width: 100%;
            max-width: 430px;
            color: var(--fontcolor);
            padding: calc(clamp(5px , 1.5% ,40px));

            .auth-body {
                padding: calc(clamp(5px , 1.5vw ,15px));
            }
        }
    }
</style>